import axios from 'axios';
import { proxyUrlMode as currentEnv } from '../setupEnvironment';

const env = currentEnv === 'prod' ? 'api' : 'api-preprod';

const fetchAllCountries = async () => {
  const urls = [
    `https://${env}.nike.com/store/store_views/v2?search=(storeStatus==OPEN or storeStatus==UNOPENED or storeStatus==CLOSED)&fields=address.country&count=3000`,
    `https://${env}.nike.com/store/store_views/v2?search=(storeStatus==OPEN or storeStatus==UNOPENED or storeStatus==CLOSED)&fields=address.country&count=3000&anchor=3000`
  ];

  const requests = urls.map(url => axios({
    method: 'GET',
    url: url,
    headers: {
      'nike-api-caller-id': 'com.nike:retail.sim',
    }
  }));

  const responses = await Promise.all(requests);
  const allStores = responses.flatMap(resp => resp.data.objects);

  const listOfCountries = [...new Set(allStores.map(store => store?.address?.country))];
  listOfCountries.sort();
  const countryArray = listOfCountries.map(country => ({
    value: country,
    label: country,
  }));

  return countryArray;
};

export default fetchAllCountries;
